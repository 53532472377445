
import { defineComponent, reactive, ref, onBeforeMount, inject } from 'vue'
import AppInputText from '@/components/controls/AppInputText.vue'
import { useRoute, useRouter } from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { User } from '@/types/user'
import { Room, Invitee, InviteeState } from '@/types/wishlist'
import { roomConverter, inviteeConverter } from '@/converter/wishlistConverter'
import { useStore } from '@/store/index'

export default defineComponent({
  components: { AppInputText },
  props: {
    show: {
      type: Boolean,
      reauired: true,
    },
  },
  setup() {
    const store = useStore()
    const db = firebase.firestore()
    const email = ref<string>('')
    // TODO まだ
    const invitees = ref(['sge.kawa@gmail.com'])
    let room = reactive<Partial<Room>>({})
    const route = useRoute()
    // onActivated(() => {
    //   console.log('onActivated')
    // })
    // onUpdated(() => {
    //   console.log('onUpdated')
    // })
    const { id } = route.params

    // なぜかemail呼び出しがからになる。（ログを見ると読み込まれてるみたいだけど
    // const user: User | null = inject('user', null)
    // const user = inject<User>('user')

    // console.log(user)

    const add = async () => {
      let invitee: Invitee = { rooms: {} }
      // console.log(user?.email)

      if (!email.value) {
        alert('Email is required')
        return
      }
      // console.log(store.state.user)
      const docRef = db
        .collection('invitees')
        .doc(email.value)
        .withConverter(inviteeConverter)

      // const docData = docRef.data()
      invitee = Object.assign(invitee, (await docRef.get()).data())

      if ((id as string) in invitee.rooms) {
        alert('This email address has already been invited.')
        return
      }
      invitee.rooms[id as string] = 'pending'

      docRef.set(invitee)
    }

    onBeforeMount(async () => {
      const docRef = await db
        .collection('rooms')
        .doc(id as string)
        .withConverter(roomConverter)
        .get()

      room = Object.assign(room, docRef.data())
    })

    return {
      email,
      invitees,
      add,
      room,
    }
  },
})
